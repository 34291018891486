<template>
  <div>
    <el-container v-for="(item, index) in listData" :key="index">
      <el-aside width="200px">
        <img class="timg" v-if="item.userHeadUrl" :src="item.userHeadUrl" alt="" />
        <img class="timg" v-else src="../../../assets/headpath.png" alt="" />
        <p class="t">{{ item.userName }}</p>
        <p class="ttype ttype_1" v-if="item.contentOrigin == 10">学员</p>
        <p class="ttype ttype_2" v-else-if="item.contentOrigin == 20">授课老师</p>
        <p class="ttype ttype_2" v-else-if="item.contentOrigin == 30">安知平台</p>
        <p class="ttype ttype_4" v-else>- -</p>
      </el-aside>
      <el-main>
        <div class="content">
          {{ item.content }}
        </div>
        <div class="operation">
          <img class="jb" v-if="item.reportNum> Number(0)" src="../../../assets/jinggao.png" alt="" />
          <span class="sj">{{ item.createTime }}</span>
          <!-- <i class="sc el-icon-delete" @click="oneCommentDelete(item,index)"></i> -->
          <!-- <span class="syj" @click="oneCommentSayAWord('ruleForm',item,index)">我也说一句</span> -->
          <span class="hf" @click="oneCommentReply(item.floorReplyTotalNum,item.show,index)"> {{ item.show?'收起回复': '回复('+item.floorReplyTotalNum + ')' }} </span>
        </div>
        <el-collapse-transition>
          <div class="replyContent" v-show="item.show">
            <div class="pl" v-for="(item1,index1) in item.replyList.list" :key="index1">
              <img class="tx" v-if="item1.userHeadUrl" :src="item1.userHeadUrl" alt="" />
              <img class="tx" v-else src="../../../assets/headpath.png" alt="" />
              <div class="xm">{{ item1.userName }} {{ item1.replyId != item.discussId?'@回复':":"  }}</div>
              <div class="hxm" v-if="item1.replyId != item.discussId">{{ item1.replyName }}:</div>
              <div class="nr">{{ item1.content }}</div>
              <!-- <div class="cz">
                <span class="sc" @click="twoCommentDelete(item1,index,index1)">删除</span>
                <span class="hf" @click="twoCommentSayAWord('ruleForm',item,item1,index)">回复</span>
              </div> -->
              <div class="ttype ttype_1" v-if="item1.contentOrigin == 10">学员</div>
              <div class="ttype ttype_2" v-else-if="item1.contentOrigin == 20">授课老师</div>
              <div class="ttype ttype_3" v-else-if="item1.contentOrigin == 30">安知平台</div>
              <div class="ttype ttype_4" v-else>- -</div>
              <div class="sj">{{ item1.createTime }}</div>
            </div>
            <div class="czpl">
                <div class="l" v-if="item.replyList.hasNextPage" @click="twoSeeMore(item,index)">展开查看更多</div>               
            </div>
          </div>
        </el-collapse-transition>
      </el-main>
    </el-container>
    <el-dialog
      title="回复该评论"
      :visible.sync="dialogVisible1"
      width="30%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :before-close="handleClose">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="用户类型" prop="resource">
          <el-radio-group v-model="ruleForm.resource">
            <el-radio label="10">学员</el-radio>
            <el-radio label="20">授课老师</el-radio>
            <el-radio label="30">安知平台</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="回复内容" prop="desc">
          <el-input resize="none" type="textarea" maxlength="200" v-model="ruleForm.desc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" class="bgc-bv" round size="small" @click="commentSayAWordCancel('ruleForm')">取 消</el-button>
        <el-button type="primary" class="bgc-bv" round size="small" @click="commentSayAWordSubmit('ruleForm')">评论</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "reply",
  props: ["listData","projectId","projectCourseId"],
  data() {
    return {
      // 回复的弹窗
      dialogVisible1:false,
      ruleForm: {
        resource: '',
        desc: ''
      },
      datas: {},
      rules: {
        resource: [
          { required: true, message: '请选择用户类型', trigger: 'change' }
        ],
        desc: [
          { required: true, message: '请填写回复内容', trigger: 'blur' }
        ]
      }
    };
  },
  watch: {},
  created() {},
  computed: {},
  mounted() {},
  methods: {
    // 一级评论 - 删除
    oneCommentDelete(item,index){
      this.$post("/biz/course/discuss/delete", {
        discussId: item.discussId
      })
        .then((res) => {
          this.$emit("testShowName")	        
          this.$message({
            message: '您已删除该评论',
            type: 'success'
          });
        })
        .catch(() => {
          return;
        });
    },
    // 一级评论 - 我也说一句
    oneCommentSayAWord(formName,item,index){
      this.$nextTick(()=>{
        this.$refs[formName].resetFields();
      })    
      this.datas = {
        contentOrigin: this.ruleForm.resource,
        content: this.ruleForm.desc,
        parentId: item.discussId,
        projectCourseId: this.projectCourseId,
        replyId: item.userId,
        replyName: item.userName,
        index
      }
      this.dialogVisible1 = true;
    },  
    // 一级评论 - 展开回复
    oneCommentReply(floorReplyTotalNum,show,index){
      if(floorReplyTotalNum>0) {
        this.listData[index].show = !show;
      }
    },
    // 二级评论 - 删除
    twoCommentDelete(item,index,index1){
      let _this = this;
      _this.$post("/biz/course/discuss/delete", {
        discussId: item.discussId
      })
        .then((res) => {        
          _this.listData[index].replyList.list.splice(index1,1);
          _this.$message({
            message: '您已删除该评论',
            type: 'success'
          });
        })
        .catch(() => {
          return;
        });
    },
    // 二级评论 - 回复
    twoCommentSayAWord(formName,item,item1,index){
      this.$nextTick(()=>{
        this.$refs[formName].resetFields();
      })    
      this.datas = {
        contentOrigin: this.ruleForm.resource,
        content: this.ruleForm.desc,
        parentId: item.discussId,
        projectCourseId: this.projectCourseId,
        replyId: item1.userId,
        replyName: item1.userName,
        index
      }
      this.dialogVisible1 = true;
    },
    // 二级评论 - 展开查看更多
    twoSeeMore(item,index){
      this.listData[index].currentPage ++
      const params = {
        projectId: this.projectId,
        projectCourseId: this.projectCourseId,   
        parentId: item.discussId, 
        pageNum: this.listData[index].currentPage,
        pageSize: 5,
      };
      this.$post("/biz/course/discuss/getInfo", params)
        .then((res) => {
          
          this.listData[index].replyList.list = [...this.listData[index].replyList.list,...res.data.list]
          this.listData[index].replyList.hasNextPage = res.data.hasNextPage;        
        })
        .catch(() => {
          return;
        });
    },
    // 评论 - 取消
    commentSayAWordCancel(formName){
      this.$refs[formName].resetFields();
      this.dialogVisible1 = false;
    },
    // 评论 - 确定
    commentSayAWordSubmit(formName){
      let datas = {
        ...this.datas,
        contentOrigin: this.ruleForm.resource,
        content: this.ruleForm.desc,
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post("/biz/course/discuss/insert", datas)
            .then((res) => {
              res.data.discuss.userHeadUrl = res.data.url;
              this.listData[this.datas.index].replyList.list.push(res.data.discuss);
              this.$message({
                message: '评论成功',
                type: 'success'
              });
              this.$refs[formName].resetFields();
              this.dialogVisible1 = false;
            })
            .catch(() => {
              return;
            });
        }
      });
    }
  },
};
</script>
<style lang="less" scope>
.ttype_1 {
  background: #67c23a;
  color: #fff;
}
.ttype_2 {
  background: #e6a23c;
  color: #fff;
}
.ttype_3 {
  background: #409eff;
  color: #fff;
}
.ttype_4 {
  background: #F56C6C;
  color: #fff;
}
.el-textarea__inner {
  min-height: 80px !important;
}
.el-container {
  min-height: 160px;
  overflow: hidden;
  border-bottom: 1px solid #eee;
  margin-bottom: 14px;
  &:last-child {
    border-bottom: 0;
  }
  .el-aside {
    width: 200px;
    background: #f9f9f9;
    padding: 20px 0;
    float: left;
    .timg {
      width: 80px;
      height: 80px;
      display: block;
      margin: auto;
      border-radius: 50%;
    }
    .t {
      width: 100px;
      margin: auto;
      text-align: center;
      margin-top: 10px;
    }
    .ttype {
      width: 70px;
      margin: auto;
      text-align: center;
      margin-top: 4px;
      font-size: 12px;
      border-radius: 4px;
      height: 22px;
      line-height: 22px;
    }
  }
  .el-main {
    width: 80%;
    height: 100%;
    float: left;
    padding-bottom: 6px;
    .content {
      min-height: 110px;
    }
    .operation {
      text-align: right;
      height: 25px;
      line-height: 25px;
      //   background: #f7f7f7;
      .jb {
        width: 16px;
        vertical-align: middle;
        cursor: pointer;
      }
      .sj {
        color: #909090;
        padding: 0 5px;
        font-size: 12px;
      }
      .sc {
        padding: 0 5px;
        cursor: pointer;
        &:hover {
          color: red;
        }
      }
      .syj {
        padding: 0 5px;
        color: #909090;
        font-size: 12px;
        cursor: pointer;
        &:hover {
          color: #4089fa;
        }
      }
      .hf {
        color: #4089fa;
        padding: 0 5px;
        cursor: pointer;
        font-size: 12px;
        background: #f7f7f7;
      }
    }
    .replyContent {
      overflow: hidden;
      background: #f7f7f7;
      padding: 10px 20px;
      .pl {
        clear: both;
        border-bottom: 1px solid #f1f1f1;
        margin-bottom: 6px;
        overflow: hidden;
        .tx {
          width: 30px;
          height: 30px;
          float: left;
          margin-right: 10px;
          border-radius: 50%;
        }
        .hxm {
          font-weight: 6000;
          float: left;
          height: 25px;
          line-height: 20px;
          margin-top: 5px;
          margin-right: 5px;
          font-size: 12px;
        }
        .xm {
          font-weight: 6000;
          float: left;
          height: 25px;
          line-height: 20px;
          margin-top: 5px;
          margin-right: 5px;
          font-size: 12px;
        }
        .nr {
          float: left;
          line-height: 20px;
          margin-top: 5px;
          font-size: 12px;
        }
        .sj {
          float: right;
          height: 25px;
          line-height: 20px;
          margin-top: 5px;
          margin-right: 5px;
          color: #909090;
          font-size: 12px;
        }
        .ttype {
          width: 60px;
          text-align: center;
          float: right;
          height: 20px;
          line-height: 20px;
          padding: 0 4px;
          font-size: 12px;
          border-radius: 4px;
          margin-top: 5px;
          margin-right: 5px;
        }
        .cz {
          float: right;
          height: 25px;
          line-height: 20px;
          margin-top: 5px;
          font-size: 12px;
          .sc {
            margin-right: 3px;
            cursor: pointer;
            color: #f56c6c;
          }
          .hf {
            margin-left: 3px;
            cursor: pointer;
            color: #4089fa;
          }
        }
      }
      .czpl {
        overflow: hidden;
        line-height: 30px;
        .l {
            float: left;
            font-size: 12px;
            color: #4089fa;
            cursor: pointer;
        }
        .r {
            float: right;
            font-size: 12px;
            color: #4089fa;
            cursor: pointer;
        }
      }
    }
  }
}
</style>